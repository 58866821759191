<template>
    <comp-table ref="comp_table" title="商品管理" entity="Goods" :columns="columns" table-api="WEB://Goods/FindAllToPage" delete-api="WEB://Goods/DeleteByUuid">
        <template #search="evt">
            <Input v-model="evt.search.goodsName" placeholder="商品名称"></Input>
        </template>
        <template #operate>
            <Button type="info" @click="$router.push('/goods/form')">新增</Button>
        </template>
        <template #row-operate="params">
            <Button type="info" style="margin: 3px" @click="$router.push('/goods/form?id=' + params.row.uuid)">编辑</Button>
            <Button type="error" style="margin: 3px" @click="params.event.delete({ uuid: params.row.uuid })">删除</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "@/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "商品名称",
                    key: "goodsName",
                },
                {
                    title: "商品价格",
                    key: "goodsPrice",
                },
                {
                    title: "商品类型",
                    key: "goodsType",
                    render: (h, params) => (params.row.goodsType == 1 ? "商品" : "服务"),
                },
                {
                    title: "销售类型（有无售后）",
                    key: "saleType",
                    render: (h, params) => (params.row.goodsType == 0 ? "无售后" : "有售后"),
                },
                {
                    title: "售后时间（单位：天）",
                    key: "salesTime",
                },
                {
                    title: "操作",
                    fixed: "right",
                    slot: "row-operate",
                    width: 200,
                },
            ],
        }
    },
}
</script>

<style></style>
